import React from 'react'
import LandingPage from 'templates/LandingPage'
import PropTypes from 'prop-types'

const Index = ({ location }) => {
  return (
    <LandingPage
      location={location}
      pageContext={
        {
          title: 'Caldeira Guesthouse & Surfcamp - Fajã da Caldeira de Santo Cristo',
          description: 'Natureza, simplicidade e muita tranquilidade. Descubra o encanto, as ondas e o sossego da Fajã da Caldeira de Santo Cristo.'
        }
      }
    />
  )
}

Index.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default Index
