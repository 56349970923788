import React from 'react'
import SEO from 'utils/seo'
import {
  Hero,
  Layout,
  Social,
  Intro,
  Testimonials,
  Gallery,
  Location,
  Activities,
  Footer,
  Reference
} from 'components/organisms'
import { Element } from 'react-scroll'
import { useLang } from 'hooks'
import PropTypes from 'prop-types'

const LandingPage = ({ pageContext, ...props }) => {
  const { langKey } = useLang(props.location)

  return (
    <Layout inverseTheme={true} location={props.location}>
      <SEO
        title={pageContext.title}
        description={pageContext.description}
        hasTitleTemplate={false}
        lang={langKey}
      />
      <Social />
      <Hero langKey={langKey} />
      <Element name="guesthouse">
        <Intro langKey={langKey} />
        <Testimonials langKey={langKey} />
        <Reference />
        <Gallery />
      </Element>
      <Location langKey={langKey} />
      <Activities langKey={langKey} />
      <Footer langKey={langKey} showHeader />
    </Layout>
  )
}

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  location: PropTypes.shape({}).isRequired
}

LandingPage.defaultProps = {
  pageContext: {
    title: 'Caldeira Guesthouse & Surfcamp - Fajã de Santo Cristo',
    description: null,
  },
}

export default LandingPage
